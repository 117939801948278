<template>

<div class="block">

	<com-text v-if="block.type === $constants.blockType.text" :block="block.data" />
	<com-image v-if="block.type === $constants.blockType.image" :block="block.data" />
	<com-featured-profile v-if="block.type === $constants.blockType.featuredProfile" :block="block.data" />
	<com-profiles v-if="block.type === $constants.blockType.profiles" :block="block.data" />
	<com-news v-if="block.type === $constants.blockType.news" :block="block.data" />
	<com-resources v-if="block.type === $constants.blockType.resources" :block="block.data" />
	<com-products v-if="block.type === $constants.blockType.products" :block="block.data" />
	<com-featured-events v-if="block.type === $constants.blockType.featuredEvents" :page="page" :block="block.data" />
	<com-events v-if="block.type === $constants.blockType.events" :block="block.data" />
	<com-product v-if="block.type === $constants.blockType.product" :block="block.data" />
	<com-embed v-if="block.type === $constants.blockType.embed" :block="block.data" />
	<com-videos v-if="block.type === $constants.blockType.videos" :block="block.data" />
	<com-text-image v-if="block.type === $constants.blockType.textImage" :block="block.data" />
	<com-links v-if="block.type === $constants.blockType.links" :block="block.data" />
	<com-symptoms v-if="block.type === $constants.blockType.symptoms" :block="block.data" />
	<com-treatments v-if="block.type === $constants.blockType.treatments" :block="block.data" />
	<com-conditions v-if="block.type === $constants.blockType.conditions" :block="block.data" />
	<com-cta v-if="block.type === $constants.blockType.cta" :block="block.data" />
	<com-home-charities v-if="block.type === $constants.blockType.homeCharities" :block="block.data" />
	<com-home-search v-if="block.type === $constants.blockType.homeSearch" :block="block.data" />
	<com-home-events v-if="block.type === $constants.blockType.homeEvents" :block="block.data" />
	<com-home-pages v-if="block.type === $constants.blockType.homePages" :block="block.data" />
	<com-home-help v-if="block.type === $constants.blockType.homeHelp" :block="block.data" />
	<com-form v-if="block.type === $constants.blockType.form" :block="block.data" />
	<com-form v-if="block.type === $constants.blockType.directoryApplication" :block="block.data" />
	<com-text-columns v-if="block.type === $constants.blockType.textColumns" :block="block.data" />
	<com-directory-search v-if="block.type === $constants.blockType.directorySearch" :block="block.data" />

</div>

</template>

<script>

export default {

	props: ['block', 'page'],

	components: {
		'com-text': () => import('./block/Text'),
		'com-image': () => import('./block/Image'),
		'com-featured-profile': () => import('./block/FeaturedProfile'),
		'com-profiles': () => import('./block/Profiles'),
		'com-news': () => import('./block/News'),
		'com-resources': () => import('./block/Resources'),
		'com-products': () => import('./block/Products'),
		'com-featured-events': () => import('./block/FeaturedEvents'),
		'com-events': () => import('./block/Events'),
		'com-product': () => import('./block/Product'),
		'com-embed': () => import('./block/Embed'),
		'com-videos': () => import('./block/Videos'),
		'com-text-image': () => import('./block/TextImage'),
		'com-links': () => import('./block/Links'),
		'com-symptoms': () => import('./block/Symptoms'),
		'com-treatments': () => import('./block/Treatments'),
		'com-conditions': () => import('./block/Conditions'),
		'com-cta': () => import('./block/Cta'),
		'com-home-charities': () => import('./block/HomeCharities'),
		'com-home-search': () => import('./block/HomeSearch'),
		'com-home-events': () => import('./block/HomeEvents'),
		'com-home-pages': () => import('./block/HomePages'),
		'com-home-help': () => import('./block/HomeHelp'),
		'com-form': () => import('./block/Form'),
		'com-text-columns': () => import('./block/TextColumns'),
		'com-directory-search': () => import('./block/DirectorySearch')
	}

}

</script>

<style scoped>

</style>
